import { UnknownErrorView } from "components/EmptyView";
import { Layout } from "components/Layout";

export const ErrorWrapper = ({ children, error }) => {
  if (error) {
    return (
      <Layout>
        <UnknownErrorView />
      </Layout>
    );
  }

  return children;
};
