import { collectData } from "features/collector";
import { logError } from "features/logger";
import { fetchSinglePageData } from "shared/utils";

export async function singlePageDataFetcher(context, options) {
  try {
    const shouldDebugResponse = context?.query?._debugResponse === "1";
    const { locale, query } = context;
    const requestQuery = { slug: query.requestSlug || query.slug };
    const { data, error, debugData } = await fetchSinglePageData(
      { locale, query: requestQuery, debug: shouldDebugResponse },
      options
    );

    const collectedData = await collectData(
      {
        items: data.content,
        context: {
          slug: query.slug,
        },
      },
      locale
    );

    if (error && error.status === 204) {
      return {
        notFound: true,
      };
    }

    return {
      props: { data, collectedData, error, debugData },
    };
  } catch (e) {
    logError(e, {
      scope: "singlePageDataFetcher",
      ...(context ?? {}),
      ...(options ?? {}),
    });
    return {
      props: {
        data: {},
        collectedData: {},
        error: e,
        debugData: {
          error: {
            message: e.message,
            status: e.status,
          },
          response: null,
          scope:
            "Exception is caught inside during fetching a single page data",
        },
      },
    };
  }
}
