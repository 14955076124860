import { buildWidgetId, buildWidgetInstanceId } from "features/widgets";
import { WIDGETS_REGISTRY } from "features/widgets/widgets.config";

export const EmptyConfig = null;

const ITEMS_NUMBER = {
  three: 3,
  five: 5,
};

export const widgetCollectorConfigResolvers = {
  [buildWidgetId("002")]: (payload) => {
    const configs = [];
    const instanceId = buildWidgetInstanceId("002", payload.id);

    const currentDate = new Date();

    if (payload.events) {
      configs.push({
        entity: "events",
        sort: "eventDate:ASC",
        populate: {
          previewImage: "*",
          eventCategories: {
            populate: "deep,1",
          },
        },
        filters: {
          active: {
            $eq: true,
          },
        },
        pagination: {
          limit: "7",
        },
        storageKey: `${instanceId}.events`,
      });
    }
    if (payload.articles) {
      configs.push({
        entity: "articles",
        sort: "date:DESC",
        populate: {
          articleCategories: {
            populate: "deep,1",
          },
          previewImage: {
            populate: "*",
          },
        },
        filters: {
          date: {
            $lte: currentDate,
          },
        },
        pagination: {
          limit: "14",
        },
        storageKey: `${instanceId}.articles`,
      });
    }
    if (payload.programs) {
      configs.push({
        entity: "courses",
        populate: "deep,1",
        pagination: {
          limit: "7",
        },
        storageKey: `${instanceId}.courses`,
      });
    }

    return configs.length > 0 ? configs : EmptyConfig;
  },
  [buildWidgetId("012")]: (payload) => {
    const instanceId = buildWidgetInstanceId("012", payload.id);
    if (payload.contentType === "event") {
      const eventCategoriesSlug =
        payload?.eventCategories?.map(({ slug }) => slug) ?? null;

      return eventCategoriesSlug
        ? {
            entity: "events",
            filters: {
              eventCategories: {
                slug: {
                  $in: eventCategoriesSlug,
                },
              },
            },
            sort: "eventDate:ASC",
            populate: {
              previewImage: "*",
            },
            fields: ["title", "slug", "eventDate"],
            storageKey: `${instanceId}.events`,
          }
        : {
            entity: "events",
            sort: "eventDate:ASC",
            pagination: {
              limit: "7",
            },
            fields: ["title", "slug", "eventDate"],
          };
    }

    if (payload.contentType === "article") {
      const articlesCategoriesSlug =
        payload?.articleCategories?.map(({ slug }) => slug) ?? null;

      return articlesCategoriesSlug
        ? {
            entity: "articles",
            filters: {
              articleCategories: {
                slug: {
                  $in: articlesCategoriesSlug,
                },
              },
            },
            sort: "date:DESC",
            populate: {
              previewImage: "*",
            },
            fields: ["title", "slug", "date"],
            storageKey: `${instanceId}.articles`,
          }
        : {
            entity: "articles",
            sort: "date:DESC",
            pagination: {
              limit: "7",
            },
            fields: ["title", "slug", "date"],
            storageKey: `${instanceId}.articles`,
          };
    }

    return EmptyConfig;
  },
  [buildWidgetId("027")]: (payload) => {
    const instanceId = buildWidgetInstanceId("027", payload.id);

    const eventsAmount = payload?.itemsAmount ?? "three";
    const eventCategories = payload?.eventCategories;
    const eventCategoriesSlugs = eventCategories?.map(({ slug }) => slug) ?? [];
    const eventsType = payload?.eventType ?? "active";
    const eventsTypeBoolean = Boolean(eventsType === "active");

    return eventCategoriesSlugs.length > 0
      ? {
          entity: "events",
          populate: {
            previewImage: "*",
            eventCategories: { populate: "*" },
          },
          pagination: {
            limit: ITEMS_NUMBER[eventsAmount],
          },
          sort: "eventDate:ASC",
          filters: {
            eventCategories: {
              slug: {
                $in: eventCategoriesSlugs,
              },
            },
            active: {
              $eq: eventsTypeBoolean,
            },
          },
          fields: ["*"],
          storageKey: `${instanceId}.events`,
        }
      : EmptyConfig;
  },
  [buildWidgetId("031", WIDGETS_REGISTRY.HERO)]: (payload, context) => {
    const instanceId = buildWidgetInstanceId(
      "031",
      payload.id,
      WIDGETS_REGISTRY.HERO
    );

    const eventSlug = context?.slug ?? null;

    return {
      entity: "events",
      filters: {
        slug: {
          $eq: eventSlug,
        },
      },
      populate: {
        previewImage: "*",
        eventCategories: { populate: "*" },
        request: {
          populate: "*",
        },
      },
      fields: ["*"],
      storageKey: `${instanceId}.events`,
    };
  },
  [buildWidgetId("058")]: (payload) => {
    const instanceId = buildWidgetInstanceId("058", payload.id);
    const projectCategories =
      payload?.projectCategories.map((item) => item.slug) ?? [];

    return {
      entity: "projects",
      fields: ["title", "description", "slug"],
      populate: {
        previewImage: { populate: "*" },
      },
      filters: {
        projectCategory: {
          slug: {
            $in: projectCategories,
          },
        },
      },
      storageKey: `${instanceId}.projects`,
    };
  },
  [buildWidgetId("064")]: (payload) => {
    const instanceId = buildWidgetInstanceId("064", payload.id);

    return {
      entity: "courses",
      fields: ["title", "slug"],
      populate: "deep,1",
      storageKey: `${instanceId}.courses`,
    };
  },
  [buildWidgetId("067")]: (payload) => {
    const instanceId = buildWidgetInstanceId("067", payload.id);

    const eventCategoriesSlugs =
      payload.eventCategories?.map(({ slug }) => slug) ?? [];
    const eventsType = payload?.eventType ?? "active";
    const eventsTypeBoolean = Boolean(eventsType === "active");

    return eventCategoriesSlugs.length > 0
      ? {
          entity: "events",
          populate: {
            eventCategories: { populate: "*" },
          },
          filters: {
            eventCategories: {
              slug: {
                $in: eventCategoriesSlugs,
              },
            },
            active: {
              $eq: eventsTypeBoolean,
            },
          },
          sort: "eventDate:DESC",
          fields: ["title", "slug", "eventTimeDescription"],
          storageKey: `${instanceId}.events`,
        }
      : EmptyConfig;
  },
  [buildWidgetId("071")]: (payload, context) => {
    const configs = [];
    const instanceId = buildWidgetInstanceId("071", payload.id);

    const currentDate = new Date();

    configs.push(
      {
        entity: "articles",
        populate: {
          articleCategories: { populate: "deep,1" },
          previewImage: "*",
        },
        filters: {
          articleCategories: {
            slug: {
              $in: context.slug,
            },
          },
          date: {
            $lte: currentDate,
          },
        },
        sort: "date:DESC",
        pagination: {
          pagination: {
            page: 1,
            pageSize: 5,
          },
        },
        storageKey: `${instanceId}.articles`,
      },
      {
        entity: "article-categories",
        disableRequiredFilters: false,
        storageKey: `${instanceId}.article-categories`,
      }
    );

    return configs.length > 0 ? configs : EmptyConfig;
  },
  [buildWidgetId("072")]: (payload) => {
    const configs = [];
    const instanceId = buildWidgetInstanceId("072", payload.id);

    const currentDate = new Date();

    configs.push(
      {
        entity: "articles",
        populate: {
          articleCategories: { populate: "deep,1" },
          previewImage: "*",
        },
        filters: {
          date: {
            $lte: currentDate,
          },
        },
        sort: "date:DESC",
        pagination: {
          page: 1,
          pageSize: 5,
        },
        storageKey: `${instanceId}.articles`,
      },
      {
        entity: "article-categories",
        disableRequiredFilters: false,
        storageKey: `${instanceId}.article-categories`,
      }
    );

    return configs.length > 0 ? configs : EmptyConfig;
  },
  [buildWidgetId("079")]: (payload, context) => {
    const instanceId = buildWidgetInstanceId("079", payload.id);
    const eventCategoriesSlug =
      payload.eventCategories?.map((item) => item.slug) ?? [];

    const currentEventSlug = context.slug;

    return eventCategoriesSlug.length > 0
      ? {
          entity: "events",
          populate: {
            eventCategories: { populate: "*" },
          },
          filters: {
            eventCategories: {
              slug: {
                $in: eventCategoriesSlug,
              },
            },
            active: {
              $eq: true,
            },
            slug: {
              $ne: currentEventSlug,
            },
          },
          sort: "eventDate:ASC",
          pagination: {
            limit: "4",
          },
          fields: ["*"],
          storageKey: `${instanceId}.events`,
        }
      : EmptyConfig;
  },
  [buildWidgetId("l012", WIDGETS_REGISTRY["LAYOUT-WIDGET"])]: (
    payload,
    context
  ) => {
    const instanceId = buildWidgetInstanceId(
      "l012",
      payload.id,
      WIDGETS_REGISTRY["LAYOUT-WIDGET"]
    );

    const articlesCategoriesSlug =
      context?.articleCategories?.map(({ slug }) => slug) ?? [];

    const articleCurrentSlug = context.slug;
    const todayDate = new Date();

    return articlesCategoriesSlug.length > 0
      ? {
          entity: "articles",
          filters: {
            articleCategories: {
              slug: {
                $in: articlesCategoriesSlug,
              },
            },
            slug: {
              $ne: articleCurrentSlug,
            },
            date: {
              $lte: todayDate,
            },
          },
          sort: "date:DESC",
          pagination: {
            limit: "7",
          },
          populate: {
            previewImage: "*",
          },

          fields: ["title", "slug", "date"],
          storageKey: `${instanceId}.articles`,
        }
      : {
          entity: "articles",
          sort: "date:DESC",
          pagination: {
            limit: "7",
          },
          filters: {
            slug: {
              $ne: articleCurrentSlug,
            },
            date: {
              $lte: todayDate,
            },
          },
          fields: ["title", "slug", "date"],
          storageKey: `${instanceId}.articles`,
        };
  },
  [buildWidgetId("l031", WIDGETS_REGISTRY["LAYOUT-WIDGET"])]: (
    payload,
    context
  ) => {
    const instanceId = buildWidgetInstanceId(
      "l031",
      payload.id,
      WIDGETS_REGISTRY["LAYOUT-WIDGET"]
    );

    const eventSlug = context.slug;

    return {
      entity: "events",
      filters: {
        slug: {
          $eq: eventSlug,
        },
      },
      populate: {
        previewImage: "*",
        eventCategories: { populate: "*" },
        request: {
          populate: "*",
        },
      },
      fields: ["*"],
      storageKey: `${instanceId}.events`,
    };
  },
  [buildWidgetId("l035", WIDGETS_REGISTRY["LAYOUT-WIDGET"])]: (
    payload,
    context
  ) => {
    const instanceId = buildWidgetInstanceId(
      "l035",
      payload.id,
      WIDGETS_REGISTRY["LAYOUT-WIDGET"]
    );

    const courseSlug = context.slug;

    return {
      entity: "courses",
      filters: {
        slug: {
          $eq: courseSlug,
        },
      },
      populate: {
        image: "*",
        button: { populate: "*" },
      },
      fields: ["*"],
      storageKey: `${instanceId}.courses`,
    };
  },
  [buildWidgetId("l066", WIDGETS_REGISTRY["LAYOUT-WIDGET"])]: (payload) => {
    const instanceId = buildWidgetInstanceId(
      "l066",
      payload.id,
      WIDGETS_REGISTRY["LAYOUT-WIDGET"]
    );
    const formTitle = payload?.title ?? "subscription";

    return {
      entity: "forms",
      filters: {
        title: {
          $eq: formTitle,
        },
      },
      fields: ["*"],
      storageKey: `${instanceId}.forms`,
    };
  },
  [buildWidgetId("l079", WIDGETS_REGISTRY["LAYOUT-WIDGET"])]: (
    payload,
    context
  ) => {
    const instanceId = buildWidgetInstanceId(
      "l079",
      payload.id,
      WIDGETS_REGISTRY["LAYOUT-WIDGET"]
    );

    const currentEventSlug = context.slug;

    return {
      entity: "events",
      populate: {
        eventCategories: { populate: "*" },
      },
      filters: {
        active: {
          $eq: true,
        },
        slug: {
          $ne: currentEventSlug,
        },
      },
      pagination: {
        limit: "4",
      },
      sort: "eventDate:ASC",
      fields: ["*"],
      storageKey: `${instanceId}.events`,
    };
  },
  [buildWidgetId("l081", WIDGETS_REGISTRY["LAYOUT-WIDGET"])]: (
    payload,
    context
  ) => {
    const instanceId = buildWidgetInstanceId(
      "l081",
      payload.id,
      WIDGETS_REGISTRY["LAYOUT-WIDGET"]
    );

    return {
      entity: "articles",
      populate: {
        previewImage: "*",
      },
      filters: {
        slug: {
          $eq: context.slug,
        },
      },
      fields: ["title", "slug"],
      storageKey: `${instanceId}.articles`,
    };
  },
  [buildWidgetId("l082", WIDGETS_REGISTRY["LAYOUT-WIDGET"])]: (
    payload,
    context
  ) => {
    const instanceId = buildWidgetInstanceId(
      "l082",
      payload.id,
      WIDGETS_REGISTRY["LAYOUT-WIDGET"]
    );

    const currentDate = context?.date ?? null;

    return {
      entity: "articles",
      populate: {
        previewImage: "*",
      },
      sort: "date:DESC",
      filters: {
        date: {
          $lt: currentDate,
        },
      },
      pagination: {
        limit: 1,
      },
      fields: ["title", "slug", "date"],
      storageKey: `${instanceId}.articles`,
    };
  },
  [buildWidgetId("l086", WIDGETS_REGISTRY["LAYOUT-WIDGET"])]: (
    payload,
    context
  ) => {
    const instanceId = buildWidgetInstanceId(
      "l086",
      payload.id,
      WIDGETS_REGISTRY["LAYOUT-WIDGET"]
    );

    const articleSlug = context.slug;

    return {
      entity: "articles",
      populate: {
        articleCategories: { populate: "deep,1" },
      },
      filters: {
        slug: {
          $eq: articleSlug,
        },
      },
      fields: ["date"],
      storageKey: `${instanceId}.articles`,
    };
  },
};
