import { Seo } from "components/Seo";
import { useTranslation } from "features/translations";
import { useRouter } from "next/router";
import { Button, Heading5 } from "shared/components";

import styles from "./EmptyView.module.scss";

export const UnknownErrorView = () => {
  const { t } = useTranslation();
  const { reload } = useRouter();

  const title = t("empty_content_view.unknown_error");

  return (
    <>
      <Seo data={{ title }} />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.icon}>!</div>

          <Heading5 as="h1" color="dark">
            {title}
          </Heading5>

          <Button
            onClick={reload}
            appearance="outlined"
            className={styles.button}
          >
            {t("empty_content_view.reload_page")}
          </Button>
        </div>
      </div>
    </>
  );
};
