import { ErrorWrapper } from "components/ErrorWrapper";
import { Layout } from "components/Layout";
import { Seo } from "components/Seo";
import { WidgetsRenderer } from "components/WidgetsRenderer";
import { PageDataProvider } from "shared/contexts/PageData";

const defaultDebugData = {};
export function SinglePageTemplate({ data, collectedData, error, debugData }) {
  return (
    <ErrorWrapper error={error}>
      <PageDataProvider value={{ data, collectedData, debugData }}>
        <Layout>
          <Seo data={data.seo} />
          <WidgetsRenderer registry="hero" items={data.hero} />
          <WidgetsRenderer registry="content" items={data.content} />
        </Layout>
      </PageDataProvider>
    </ErrorWrapper>
  );
}
