import { fetchAPI } from "features/cms";

import { extractCollectorConfig } from "./extractCollectorConfig";

export const collectData = async ({ items, context }, locale) => {
  const collectorConfig = extractCollectorConfig(items, context);
  const collectedData = {};

  const preparedPromises = collectorConfig.map((currentConfig) => {
    return fetchAPI(
      `/${currentConfig.entity}`,
      {
        ...(currentConfig.fields && { fields: currentConfig.fields }),
        ...(currentConfig.sort && { sort: currentConfig.sort }),
        ...(currentConfig.pagination && {
          pagination: currentConfig.pagination,
        }),
        ...(currentConfig.populate && { populate: currentConfig.populate }),

        filters: {
          ...currentConfig.filters,
        },
      },
      !currentConfig.disableRequiredFilters && locale
    );
  });

  const results = await Promise.allSettled(preparedPromises);

  results.forEach((res, index) => {
    if (res.status !== "fulfilled") {
      collectedData[collectorConfig[index].storageKey] = [];
    } else {
      collectedData[collectorConfig[index].storageKey] = res?.value?.data ?? [];
    }
  });

  return collectedData;
};
