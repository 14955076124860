import {
  EmptyConfig,
  widgetCollectorConfigResolvers,
} from "features/collector/collector.config";
import { logError } from "features/logger";

export const extractCollectorConfig = (items, context) => {
  try {
    return items
      .map((item) => {
        const configResolver = widgetCollectorConfigResolvers[item.__component];
        if (configResolver) {
          return typeof configResolver === "function"
            ? configResolver(item, context)
            : configResolver;
        }

        return EmptyConfig;
      })
      .flat(1)
      .filter(Boolean);
  } catch (e) {
    logError(e, {
      scope: "extractCollectorConfig",
      collection: "-multi-",
      items,
      context,
    });
    return [];
  }
};
